import React from 'react'

const Party = () => {
  return (
    <iframe
        title="External Content"
        src="https://medicare-website-five.vercel.app/"
        style={{ width: '100%', height: '100vh', border: 'none' }}
      />
  )
}

export default Party